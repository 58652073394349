// extracted by mini-css-extract-plugin
export var column = "ResourceCategoryOptions__column__RfTSf";
export var flex = "ResourceCategoryOptions__flex__ZlXD0";
export var flexColumn = "ResourceCategoryOptions__flexColumn__QblTW";
export var gap1 = "ResourceCategoryOptions__gap1__gaBYq";
export var gap2 = "ResourceCategoryOptions__gap2__Sfsta";
export var gap3 = "ResourceCategoryOptions__gap3__csW9t";
export var gap4 = "ResourceCategoryOptions__gap4__VNdWl";
export var gap5 = "ResourceCategoryOptions__gap5__i_B2q";
export var popoverBody = "ResourceCategoryOptions__popoverBody__GKK7U";
export var popoverBodyLink = "ResourceCategoryOptions__popoverBodyLink__LMxzd";
export var popoverBodyLinkIcon = "ResourceCategoryOptions__popoverBodyLinkIcon__pkC4_";
export var row = "ResourceCategoryOptions__row__gA6Y1";