// extracted by mini-css-extract-plugin
export var actionBar = "ResourceCategoriesDashboard__actionBar__pYp0A";
export var actionBarDropdown = "ResourceCategoriesDashboard__actionBarDropdown__ZCBwC";
export var actionBarError = "ResourceCategoriesDashboard__actionBarError__Q2TTu";
export var actionBarInfo = "ResourceCategoriesDashboard__actionBarInfo__e3SZE";
export var actionBarInner = "ResourceCategoriesDashboard__actionBarInner__ztAt7";
export var actionBarSpinner = "ResourceCategoriesDashboard__actionBarSpinner__hq1nT";
export var addNewProductButton = "ResourceCategoriesDashboard__addNewProductButton__HWwAb";
export var badge = "ResourceCategoriesDashboard__badge__YJu5k";
export var column = "ResourceCategoriesDashboard__column__ZdiW1";
export var controlRightAlign = "ResourceCategoriesDashboard__controlRightAlign___CMsC";
export var createdAt = "ResourceCategoriesDashboard__createdAt__G3Jjm";
export var dropdown = "ResourceCategoriesDashboard__dropdown__Luqxq";
export var dropdownBody = "ResourceCategoriesDashboard__dropdownBody__mLjuK";
export var dropdownBodyLink = "ResourceCategoriesDashboard__dropdownBodyLink__pJfNF";
export var dropdownBodyRow = "ResourceCategoriesDashboard__dropdownBodyRow__YTKu8";
export var fetchError = "ResourceCategoriesDashboard__fetchError__gts6b";
export var flex = "ResourceCategoriesDashboard__flex__LqSV_";
export var flexColumn = "ResourceCategoriesDashboard__flexColumn__KO14A";
export var gap1 = "ResourceCategoriesDashboard__gap1__TA6C8";
export var gap2 = "ResourceCategoriesDashboard__gap2__Z5CKA";
export var gap3 = "ResourceCategoriesDashboard__gap3__ybpzK";
export var gap4 = "ResourceCategoriesDashboard__gap4__jrWI0";
export var gap5 = "ResourceCategoriesDashboard__gap5__P93hX";
export var gridColumns = "ResourceCategoriesDashboard__gridColumns__jxchI";
export var header = "ResourceCategoriesDashboard__header__RxUnZ";
export var headerControls = "ResourceCategoriesDashboard__headerControls__x_F7f";
export var hidden = "ResourceCategoriesDashboard__hidden__w51p4";
export var label = "ResourceCategoriesDashboard__label__r0zNd";
export var layout = "ResourceCategoriesDashboard__layout__Fi9aB";
export var navigationName = "ResourceCategoriesDashboard__navigationName__xqqgx";
export var pageContainer = "ResourceCategoriesDashboard__pageContainer__i9H0d";
export var pagination = "ResourceCategoriesDashboard__pagination__RXUNF";
export var resourceCategory = "ResourceCategoriesDashboard__resourceCategory__JzhPB";
export var resourceCategoryMenu = "ResourceCategoriesDashboard__resourceCategoryMenu__sesL7";
export var resourceCount = "ResourceCategoriesDashboard__resourceCount__fcOd_";
export var row = "ResourceCategoriesDashboard__row__vtWRW";
export var searchField = "ResourceCategoriesDashboard__searchField__hqBmd";
export var shown = "ResourceCategoriesDashboard__shown___zuHP";
export var table = "ResourceCategoriesDashboard__table__XteeQ";
export var tableBody = "ResourceCategoriesDashboard__tableBody__iuixk";
export var tableBodyMinHeight = "ResourceCategoriesDashboard__tableBodyMinHeight__RHkGd";
export var tableHeader = "ResourceCategoriesDashboard__tableHeader__EQLds";
export var tableHeaderCell = "ResourceCategoriesDashboard__tableHeaderCell__QYO6N";
export var title = "ResourceCategoriesDashboard__title__SEDKa";
export var updatedAt = "ResourceCategoriesDashboard__updatedAt__Bwo5i";